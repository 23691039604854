import { useState, useRef, useEffect } from 'react'
import { TextField, Typography, FormLabel, Button, FormControlLabel, Switch, Container, Grid } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import BarcodeReader from 'react-barcode-reader'
import BeepSound from '../assets/beep.wav'
import { api } from "../services";
import { format } from 'date-fns'


function Page({ check_url, admit_url, banner_key, prefill_url }) {
  const beepRef = useRef(null)

  const [client, setClient] = useState('')
  const [id_no, setIdNo] = useState('')
  const [page, setPage] = useState(1)
  const [result, setResult] = useState(null)
  const [banner_url, setBannerUrl] = useState('')
  const [is_scan, setIsScan] = useState(false)
  const [prefill, setPrefill] = useState([])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const client = urlParams.get('counter')
    setClient(client)

    onStart()
    getPrefill()

    const intervalId = setInterval(getPrefill, 10000)
    return () => clearInterval(intervalId)

  }, [])

  

  const onStart = async () => {
    let banner_res = await api.get(`config/${banner_key}`)
    setBannerUrl(banner_res.data.value)
    

  }
  const getPrefill = async() => {
    let prefill_res = await api.get(`${prefill_url}`)
    setPrefill(prefill_res.data)
  }



  // const onChangeID = (e) => {
  //   setIdNo(e.target.outerText)
  //   setIsScan(false)
  // }
  const onChangeID = (e, newInput) => {
    setIdNo(newInput);
    setIsScan(false);
  };

  const onBarcodeReaderError = (data) => {
    alert(`Error ${data}`)
  }
  const onBarcodeReaderScan = (data) => {
    // if (page == 1) {
    setIdNo('')
    setIdNo(data)
    setIsScan(true)
    beepRef.current?.play()
    // }
  }
  const onNext = async (e) => {
    e?.preventDefault()
    console.log('onNext', id_no)
    if (id_no == '') {
      alert("Identification Number cannot be Empty")
      return
    }
    if (is_scan) {
      return
    }
    let res = await api.post(check_url, { id: id_no })
    let { data } = res
    if (data.error) {
      setPage(3)
      return
    }
    setResult(data)
    setPage(2)
  }
  const onBack = () => {
    setPage(1)
    console.log('onback')
    setIdNo("")
  }
  const onAdmit = async () => {
    let res = await api.post(admit_url, { id: id_no, client })
    let { data } = res
    if (data.error) {
      alert(data.message)
      return
    }
    setPage(1)
    setIdNo("")
  }

  const onPrint = async () => {

    var windowContent = '<!DOCTYPE html>';
    windowContent += '<html>'
    windowContent += `<head><title>Registration-${result?.name}-${result?.identity}</title><style>@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');</style><style>p { font-family: Roboto !important }</style></head>`;
    windowContent += '<body>'

    windowContent += `<div style="position: fixed; top: 10px; left: 0; width: 100%;font-size: 24px; height: 177px;"><p style="position: absolute; bottom: 0; text-align: center; width: 100%"><b>${result?.name}</b></p></div>`
    windowContent += `<p style="position: fixed; top: 155px; left: 0; width: 100%;text-align: center;font-size: 18px;" >${result?.seat}</p>`
    windowContent += `<p style="position: fixed; top: 220px; left: 0;width: 100%;text-align: center;font-size: 18px" >${result?.session}</p>`
    windowContent += `<p style="position: fixed; top: 325px; right: 20px;text-align: right;font-size: 16px;">${result?.identity}</p>`
    windowContent += '</body>';
    windowContent += '</html>';


    var printWin = window.open('', '', 'width=385,height=865');
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();
  }


  return (
    <div>
      <img className="banner" src={banner_url} width="100%" />

      {page == 1 ?
        <div className="content" >
          <div className="content-child" key="2" >
            <form onSubmit={onNext} >
              <div>
                <FormLabel component="legend">Identification Number / Contact Number / Name :</FormLabel>
                
                <Autocomplete autoFocus fullWidth
                  options={prefill}
                  groupBy={(option) => option.type}
                  onInputChange={(e, newInput) => onChangeID(e, newInput)}
                  getOptionLabel={(option) => `${option.value}`}
                  renderInput={(params) => <TextField
                    {...params} value={id_no} margin="normal" variant="outlined"
                    onKeyDown={e => {
                      if (e.code === 'enter' && e.target.value) {
                        onChangeID(e, e.target.value);
                      }
                    }}
                  />}
                />
                {/* <Button color="primary" size="large" variant="contained" onClick={onNext} >Enter</Button> */}
                <Button color="primary" size="large" variant="contained" type="submit" >Enter</Button>
              </div>
              <BarcodeReader
                onError={onBarcodeReaderError}
                onScan={onBarcodeReaderScan}
              />
            </form>

          </div>
        </div>

        : page == 2 ?
          <Container maxWidth="sm" className="result" >
            {result &&
              <Grid container spacing={4} justifyContent="space-around"  >
                {result?.is_admitted ?
                  <Grid item xs={12}>
                    <Typography className="admitted">Guest Admitted on <b>{format(new Date(result?.admitted_at), "yyyy LL dd hh:mm aa ")}</b></Typography>
                  </Grid>
                  : null
                }

                <Grid item xs={6}>
                  <Typography>Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>: {result?.name}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography>Identification Number</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>: {result?.identity}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography>Particular</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>: {result?.seat}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography>Detail 1</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>: {result?.session}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography>Detail 2</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>: {result?.guest_type}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography>Detail 3</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>: {result?.detail_3}</Typography>
                </Grid>


                <Grid item xs={4}>
                  <Button color="default" fullWidth variant="contained" onClick={onBack} >Back</Button>
                </Grid>
                {!result?.is_admitted &&
                  <Grid item xs={4}>
                    <Button color="primary" fullWidth variant="contained" onClick={onAdmit} >Admit</Button>
                  </Grid>
                }
                <Grid item xs={4}>
                  <Button color="default" fullWidth variant="contained" onClick={onPrint} >Print</Button>
                </Grid>

              </Grid>
            }
          </Container>
          :
          <Container maxWidth="sm" className="result" >
            <Typography className="manual" >Record not found. Please proceed to the HELP DESK for assistance</Typography>
            <Grid container justifyContent="center" >
              <Grid item xs={6}>
                <Button color="default" fullWidth variant="contained" onClick={onBack} >Back</Button>
              </Grid>
            </Grid>
          </Container>
      }




      <audio ref={beepRef} >
        <source src={BeepSound}></source>
      </audio>

    </div>
  )


}

export default Page