import Session from "./Session";

function Page() {
    

    return (
        <Session url="session3" session_name="Event3" thumbnail_key="thumbnail_3" percent_url="percent3" />
    )
}

export default Page