import { useState, useRef, useEffect } from 'react'
import { TextField, Typography, FormLabel, Button, FormControlLabel, Switch, Container, Grid } from '@material-ui/core'
import S from './S'


function Page(props) {
    

    return (
        <S check_url="check1" admit_url="admint1" banner_key="banner_1" prefill_url="prefill1" />
    )


}

export default Page