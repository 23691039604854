import { useEffect, useRef, useState } from "react";
import MaterialTable from "material-table";
import { admin_api, api } from "../services";
import CSVReader from "react-csv-reader";
import hp from "../services/hp";
import { Switch, Typography } from "@material-ui/core";
import { format } from "date-fns";
import moment from "moment/moment";

function Page({ url, session_name, thumbnail_key, percent_url }) {
  const tableRef = useRef();

  const [thumbnail_img, setThumbnailImg] = useState("");
  const [percent, setPercent] = useState(0);
  const [input_key, setInputKey] = useState(Math.random().toString(36));
  const [total, setTotal] = useState(0);
  const [autoRefresh, setAutoRefresh] = useState(false);

  useEffect(() => {
    onStart();

    if (autoRefresh) {
      let auto_refresh_interval = setInterval(() => {
        tableRef?.current?.onQueryChange();
        onStart();
      }, 10000);

      return () => {
        clearInterval(auto_refresh_interval);
      };
    }
  }, [autoRefresh]);
  console.log(percent);

  const onStart = async () => {
    let percent_res = await admin_api.get(percent_url);
    setPercent(percent_res.data);

    if (localStorage.getItem(thumbnail_key)) {
      setThumbnailImg(localStorage.getItem(thumbnail_key));
    } else {
      let thumbnail_img_res = await api.get(`config/${thumbnail_key}`);
      setThumbnailImg(thumbnail_img_res.data.value);
    }
  };

  const onAttach = async (data, fileInfo) => {
    if (data.length == 0) {
      alert("CSV file is empty");
      return;
    }
    setInputKey(Math.random().toString(36));
    let _data = JSON.stringify(data);
    let res = await admin_api.post(url, { data: _data });
    res = res.data;
    if (res.error) {
      alert(res.message);
      return;
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  return (
    <div className="table">
      <div className="attend">
        <Typography variant="h5">
          Attendance rate: {percent ? percent : 0}%
        </Typography>
      </div>

      <div className="autoRefresh">
        <Switch
          name="gilad"
          color="primary"
          checked={autoRefresh}
          onChange={() => setAutoRefresh((prev) => !prev)}
        />
        <p>Auto Refresh</p>
      </div>

      <MaterialTable
        tableRef={tableRef}
        title={<img src={thumbnail_img} width="200px" />}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              const body = {
                ...newData,
                admitted_at: moment(newData.admitted_at).format(
                  "yyyy-MM-DD hh:mm:ss"
                ),
              };
              const query = `${url}/create`;
              const response = admin_api.post(query, body);
              tableRef.current && tableRef.current.onQueryChange();
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const body = {
                ...newData,
                admitted_at: moment(newData.admitted_at).format(
                  "yyyy-MM-DD hh:mm:ss"
                ),
              };
              const query = `${url}/update/${oldData.id}`;
              const response = admin_api.put(query, body);
              tableRef.current && tableRef.current.onQueryChange();
              resolve();
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              const query = `${url}/delete/${oldData.id}`;
              const response = admin_api.delete(query);
              tableRef.current && tableRef.current.onQueryChange();
              resolve();
            }),
        }}
        columns={[
          { title: "Name", field: "name" },
          { title: "ID", field: "identity" },
          { title: "Particular", field: "seat" },
          { title: "Contact No.", field: "phone" },
          { title: "Detail 1", field: "session" },
          { title: "Detail 2", field: "guest_type" },
          { title: "Detail 3", field: "detail_3" },
          { title: "Attendance", field: "is_admitted", type: "boolean" },
          { title: "Time Admitted", field: "admitted_at", type: "datetime" },
          { title: "Counter", field: "client" },
        ]}
        options={{
          // pageSize: 10,
          // pageSizeOptions: [10, 20, 50, 100, 300, 1000, total],
          pageSize: total,
          pageSizeOptions: [total],
          actionsColumnIndex: -1,
          sorting: true,
          addRowPosition: "first",
          exportButton: true,
          exportFileName: `${session_name}_${format(
            new Date(),
            "yyyyLLdd_kkmm"
          )}`,
          // exportCsv: () => {
          //   console.log('export')
          // }
        }}
        data={(query) =>
          new Promise(async (resolve) => {
            let q = `${url}?limit=${query.pageSize}&offset=${
              query.page * query.pageSize
            }`;
            if (query.orderBy) {
              q += `&orderBy=${query.orderBy?.field}&orderDirection=${query.orderDirection}`;
            }
            if (query.search != "") {
              q += `&search=${query.search}`;
            }
            const result = await admin_api.get(q);
            const { data } = result;
            setTotal(data.total);
            resolve({
              data: data.data,
              page: query.page,
              totalCount: data.total,
            });
          })
        }
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
          },
          {
            icon: "publish",
            tooltip: "Upload New List",
            isFreeAction: true,
            hidden: hp.isFull() == 0,
            onClick: async () => {
              let result = await admin_api.get(`${url}`);
              let { data } = result;
              if (data.total != 0) {
                alert("Please clear the existing data first");
                return;
              }
              document.getElementById("react-csv-reader-input").click();
            },
          },
          {
            icon: "clear",
            tooltip: "Clear Existing List",
            isFreeAction: true,
            hidden: hp.isFull() == 0,
            onClick: async () => {
              let c = window.confirm("Please confirm to clear");
              if (c) {
                let res = await admin_api.delete(`${url}`);
                setPercent(0);
                tableRef.current && tableRef.current.onQueryChange();
              }
            },
          },
        ]}
      />

      <div key={input_key || ""}>
        <CSVReader
          cssClass="react-csv-input"
          cssInputClass="csv-input"
          inputId="react-csv-reader-input"
          onFileLoaded={onAttach}
          parserOptions={{
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: (header) =>
              header.toLowerCase().replace(/\W/g, "_"),
          }}
        />
      </div>
    </div>
  );
}

export default Page;
