// const api_url =
//   process.env.NODE_ENV == "development"
//     ? "http://127.0.0.1:8000"
//     : "https://mvregister-api.viabells.com";

const api_url = 'https://mvregister-api.viabells.com'

module.exports = {
  api_url,
};
