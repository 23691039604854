import { Container, Paper, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, CircularProgress, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { admin_api } from "../services";
import Session from "./Session";

function Upload({ name, onReload }) {
  const [loading, setLoading] = useState(false)

  const uploadImage = async (files) => {
    let file = files.target.files[0]
    setLoading(true)
    // let data = new FormData()
    // data.append('image', file)
    getBase64(file, async (result) => {
      const uploaded_res = await admin_api.post(`upload-image`, {'image': result} )
      const uploaded = uploaded_res.data
      const update_res = await admin_api.post('config', { name, value: uploaded })
      setLoading(false)
      onReload()
      localStorage.removeItem(name)
    })
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

  return (
    <>
      <input
        onChange={uploadImage}
        accept="image/*"
        className="hidden"
        id={`upload-${name}`}
        multiple
        type="file"
      />
      <label htmlFor={`upload-${name}`}>
        {loading ?
          <Button variant="contained" color="primary" component="span" disabled={loading} >
            <CircularProgress color="default" size={22} />
          </Button>
          :
          <Button variant="contained" color="primary" component="span"  >
            Upload
          </Button>
        }
      </label>
    </>
  )
}

function Page() {

  const [config, setConfig] = useState(null)

  useEffect(() => {
    onStart()
  }, [])

  const onStart = async () => {
    const config_res = await admin_api.get('config')
    const config = config_res.data
    let _config = {}
    config.forEach((c) => {
      _config[c.key] = c.value
    })
    setConfig(_config)
  }


  return (
    <Container maxWidth="lg" className="pt20" >

      {config ?

        <Paper >
          <Table>
            <TableHead className="primaryB">
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ fontSize: 18 }} ><b>Event 1</b></TableCell>
                <TableCell style={{ fontSize: 18 }}><b>Event 2</b></TableCell>
                <TableCell style={{ fontSize: 18 }}><b>Event 3</b></TableCell>
                <TableCell style={{ fontSize: 18 }}><b>Event 4</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              <TableRow  >
                <TableCell>Frontend Banner</TableCell>
                <TableCell>
                  <img src={config.banner_1} width="100%" />
                  <Upload name="banner_1" onReload={onStart} />
                </TableCell>
                <TableCell>
                  <img src={config.banner_2} width="100%" />
                  <Upload name="banner_2" onReload={onStart} />
                </TableCell>
                <TableCell>
                  <img src={config.banner_3} width="100%" />
                  <Upload name="banner_3" onReload={onStart} />
                </TableCell>
                <TableCell>
                  <img src={config.banner_4} width="100%" />
                  <Upload name="banner_4" onReload={onStart} />
                </TableCell>
              </TableRow>


              <TableRow  >
                <TableCell>Backend Button</TableCell>
                <TableCell>
                  <img src={config.button_1} width="100%" />
                  <Upload name="button_1" onReload={onStart} />
                </TableCell>
                <TableCell>
                  <img src={config.button_2} width="100%" />
                  <Upload name="button_2" onReload={onStart} />

                </TableCell>
                <TableCell>
                  <img src={config.button_3} width="100%" />
                  <Upload name="button_3" onReload={onStart} />
                </TableCell>
                <TableCell>
                  <img src={config.button_4} width="100%" />
                  <Upload name="button_4" onReload={onStart} />
                </TableCell>
              </TableRow>

              <TableRow  >
                <TableCell>Backend Menu Items</TableCell>
                <TableCell>
                  <img src={config.thumbnail_1} width="100%" />
                  <Upload name="thumbnail_1" onReload={onStart} />
                </TableCell>
                <TableCell>
                  <img src={config.thumbnail_2} width="100%" />
                  <Upload name="thumbnail_2" onReload={onStart} />
                </TableCell>
                <TableCell>
                  <img src={config.thumbnail_3} width="100%" />
                  <Upload name="thumbnail_3" onReload={onStart} />
                </TableCell>
                <TableCell>
                  <img src={config.thumbnail_4} width="100%" />
                  <Upload name="thumbnail_4" onReload={onStart} />
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Paper>

        :

        <div className="loader">
          <CircularProgress />
        </div>

      }

    </Container>
  )
}

export default Page