import { TextareaAutosize } from "@material-ui/core"

const hp = {

    logout: () => {
        window.location.href = "/login"
        localStorage.clear()
    },
    isFull: () => { 
        return localStorage.getItem('is_full')
    }
   
}

export default hp