import { useState, useRef, useEffect } from 'react'
import { TextField, Typography, FormLabel, Button, FormControlLabel, Switch, Container, Grid } from '@material-ui/core'
import S from './S'


function Page(props) {
    

    return (
        <S check_url="check3" admit_url="admint3" banner_key="banner_3" prefill_url="prefill3" />
    )


}

export default Page