import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Scanner from "./Scanner";
import Webcam from "./Webcam";
import Login from "./Admin/Login";
import Dashboard from "./Admin/Dashboard";
import Wrapper from "./Admin/Wrapper";
import Config from "./Admin/Config";
import Session1 from "./Admin/Session1";
import Session2 from "./Admin/Session2";
import Session3 from "./Admin/Session3";
import Session4 from "./Admin/Session4";
import S1 from "./Public/S1";
import S2 from "./Public/S2";
import S3 from "./Public/S3";
import S4 from "./Public/S4";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <PrivateRoute path="/admin/dashboard">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/admin/config">
            <Config />
          </PrivateRoute>
          <PrivateRoute path="/admin/event1">
            <Session1 />
          </PrivateRoute>
          <PrivateRoute path="/admin/event2">
            <Session2 />
          </PrivateRoute>
          <PrivateRoute path="/admin/event3">
            <Session3 />
          </PrivateRoute>
          <PrivateRoute path="/admin/event4">
            <Session4 />
          </PrivateRoute>

          <Route path="/event1">
            <S1 />{" "}
          </Route>
          <Route path="/event2">
            <S2 />{" "}
          </Route>
          <Route path="/event3">
            <S3 />{" "}
          </Route>
          <Route path="/event4">
            <S4 />{" "}
          </Route>

          <Route path="/webcam">
            <Webcam />
          </Route>
          <Route path="/login">
            <Login />
          </Route>

          <Route path="/">
            <Scanner />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("id") || localStorage.getItem("token") ? (
          <Wrapper>{children}</Wrapper>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
