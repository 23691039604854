import { useState, useRef, useEffect } from 'react'
import { TextField, Typography, FormLabel, Button, FormControlLabel, Switch, Container, Grid } from '@material-ui/core'
import S from './S'


function Page(props) {
    

    return (
        <S check_url="check4" admit_url="admint4" banner_key="banner_4" prefill_url="prefill4" />
    )


}

export default Page