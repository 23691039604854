import Session from "./Session";

function Page() {
  return (
    <Session
      url="session1"
      session_name="Event1"
      thumbnail_key="thumbnail_1"
      percent_url="percent1"
    />
  );
}

export default Page;
