import React from 'react'
import axios from 'axios'
import config from './config'


const instance = axios.create();

const apiUrl = `${config.api_url}/api`

instance.interceptors.request.use(async (config) => {
  config.baseURL = apiUrl
  return config

}, (error) => {
  return Promise.reject(error);
})

instance.interceptors.response.use(async (response) => {
  if (process.env.NODE_ENV === 'development') {
    console.log("res", response)
  }
  if (!response.data) {
    return Promise.reject(response);
  }
  return response

}, async (error) => {

  return Promise.reject(error);
})

export default instance
